<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        small
        class="primary ml-2 mt-3"
        v-on="on"
      >
        <v-icon
          small
          class="mr-1"
        >mdi-plus
        </v-icon>
        Создать
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in handleItemsByPermissions"
        :key="index"
        @click="$emit('clickItem', {id: null, ...item})"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'Create',
  data: () => ({
    createItems: [
      {
        title: 'Объявление',
        action: 'detail',
        permission: null
      },
      {
        title: 'Выгрузка',
        action: 'file',
        permission: 'downloadAdvertList'
      }
    ]
  }),
  computed: {
    handleItemsByPermissions () {
      return this.createItems.filter(item => this.$can('agent', item.permission))
    }
  }
}
</script>

<style scoped>

</style>
