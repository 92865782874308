<template>
  <v-container
    fluid
    class="pt-3 pb-0 pl-2"
  >
    <v-row class="text-left">
      <v-col
        cols="8"
        class="text-left pt-0 pb-0"
      >
        <div class="d-flex align-center">
          <AdvertDialogFilter
            :value="localFilters"
            @input="handleInputFilter($event)"
            :loading="loading"
          />
          <Autocomplete
            :value="localFilters.phoneFilter"
            @input="handlePhoneFilter($event)"
            @changeNumber="handleChangeNumber($event)"
            item-value="id"
            item-text="phone"
            placeholder="поиск по номеру телефона"
            prepend-icon="mdi-database-search"
            mask="8 ### ### ## ##"
            url="/phone/autocomplete"
            class="pl-5 flex-grow-2"
          />
          <div class="disabledBtnCustom">
            <!--<RelatedClients
              v-if="localFilters.phoneFilter || isPhoneNumberChanging"
              :phones="relatedPhone"
              @detail="$emit('detail', $event)"
            />-->
            <RelatedPhones
              v-if="localFilters.phoneFilter || isPhoneNumberChanging"
              :phones="relatedPhone"
              @detail="$emit('detail', $event)"
            />
          </div>
          <v-text-field
            :value="localFilters.advertId"
            @input="localId = $event"
            @keyup.enter="handleIdFilter(localId)"
            placeholder="поиск по ID"
            prepend-icon="mdi-nfc-search-variant "
            class="pl-5"
            style="min-width: 88px;"
          />
        </div>
      </v-col>
      <v-col
        cols="4"
        class="text-left pt-0 pb-0"
      >
        <v-select
          :value="advertType"
          @input="handleIsVerifiedFilter($event)"
          :items="advertTypeItems"
          label="выберите тип объявления"
          prepend-icon="mdi-playlist-check"
          elevation="1"
          class="text-left"
          item-value="value"
          item-text="text"
          return-object
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Filters from '@/components/views/admin/advert/Common/Filters'
import Autocomplete from '@/components/views/admin/common/Autocomplete'
import { advertTypeItems } from '@/config'
import advertControls from '@/components/mixins/controls/advertControls'
import AdvertDialogFilter from '@/components/views/admin/advert/Common/Controls/AdvertDialogFilter'
import RelatedClients from '@/components/views/admin/common/BasePhone/RelatedClients'
import RelatedPhones from '@/components/views/admin/common/RelatedPhones'

export default {
  mixins: [advertControls],
  props: {
    clientId: Number,
    clientAdvertId: Number
  },
  name: 'Controls',
  components: { RelatedClients, AdvertDialogFilter, Autocomplete, Filters, RelatedPhones },
  data () {
    return {
      advertTypeItems: advertTypeItems,
      localId: null
    }
  },
  computed: {
    advertType () {
      const value = this.advertTypeItems.find(option => option.value.isVerified === this.localFilters.isVerified && option.value.advStatus === this.localFilters.advStatus)
      return value ? value.value : this.advertTypeItems[0].value
    },
    relatedPhone () {
      return this.localFilters.phoneFilter ? [{ phone: this.localFilters.phoneFilter }] : []
    }
  }
}
</script>

<style scoped>
  .disabledBtnCustom .theme--dark.v-btn.v-btn--disabled {
    color: #fff !important;
    opacity: 0.4;
  }
</style>
