<template>
  <v-container fluid>
    <v-row class="text-left">
      <v-col cols="12">
        <v-data-table
          v-model="localSelectedItems"
          :headers="headers"
          :items="items"
          :options="options"
          @update:options="$emit('input', $event)"
          :server-items-length="itemsLength"
          :loading="loading"
          class="elevation-1 tableListAdvert"
          :footer-props="{itemsPerPageOptions:  [20,100,300]}"
          multi-sort
          dense
        >

          <template v-slot:item="{ item, index }">
            <tr
              :class="classByStatus(item)"
              @click="detail(item.id)"
            >
              <td>
                <v-checkbox
                  v-model="localSelectedItems"
                  :value="item"
                  style="margin:0px;padding:0px"
                  hide-details
                  @click.passive.stop="handleSelectionItem(item, item.isSelected)"
                />
              </td>
              <td>{{ 1 + (options.page - 1) * options.itemsPerPage + index }}</td>
              <td>{{ item.is_published ? 'Да' : 'Нет' }}</td>
              <td v-if="client_advert_id">
                <div
                  class="pa-2"
                  :class="classForColumn(item, 'viewed')"
                >{{ valueForColumn(item, 'viewed') }}
                </div>
              </td>
              <td v-if="client_advert_id">
                <div
                  class="pa-2"
                  :class="classForColumn(item, 'approved')"
                >{{ valueForColumn(item, 'approved') }}
                </div>
              </td>
              <td v-if="client_advert_id">
                <div
                  class="pa-2"
                  :class="classForColumn(item, 'offered')"
                >{{ valueForColumn(item, 'offered') }}
                </div>
              </td>
              <td>{{ formatValue('advert.exclusive_status', item.exclusive_status) }}</td>
              <td>{{ item.updated_at ? formatTodayDate(item.updated_at) : '' }}</td>
              <td>
                <div
                  v-for="phone in item.phones"
                  :key="phone.phone"
                  class="phone"
                  :class="phoneStatusClass(phone.status)"
                >
                  {{ maskValue(phone.phone, '8 ### ### ## ##') }}
                </div>
              </td>
              <td>{{
                  item.building ? formatValue('building.district_official', item.building.district_official) : ''
                }}
              </td>
              <td>{{ item.building ? formatValue('building.district_custom', item.building.district_custom) : '' }}</td>
              <td>{{ item.building ? formatValue('building.subdistrict', item.building.subdistrict) : '' }}</td>
              <td>{{
                  item.building && item.building.mainBuildingAddress ? item.building.mainBuildingAddress.street.name + ' ' +
                    item.building.mainBuildingAddress.building_number : ''
                }}
              </td>
              <td>{{ item.room_count }}</td>
              <td>{{ cutString(formatValue('advert.kitchen_type', item.kitchen_type), 4) }}</td>
              <td>{{ formatValue('advert.flat_type', item.flat_type) }}
              </td>
              <td>{{ item.floor }}</td>
              <td>{{
                  item.building && item.building.total_floors ? item.building.total_floors : item.total_floors
                }}
              </td>
              <td>{{
                  item.building ? formatValue('building.building_material', item.building.building_material ?
                    item.building.building_material : item.building_material) : ''
                }}
              </td>
              <td>{{ item.area_common }}</td>
              <td>{{ item.area_living }}</td>
              <td>{{ item.area_kitchen }}</td>
              <td>{{ item.building ? item.building.build_year : '' }}</td>
              <td>{{ formatValue('advert.flat_status', item.flat_status) }}</td>
              <td>{{ formatPrice(item.price) }}</td>
              <td>{{ item.advertImages.length ? 'Да' : 'Нет' }}</td>
              <td>{{ item.building ? item.building.is_ready ? 'Сдан' : 'Не сдан' : '' }}</td>
              <td>{{ item.id }}</td>
              <td>{{ formatValue('advert.source', item.source) }}</td>
              <td>{{ item.created_at ? formatTodayDate(item.created_at) : '' }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import headers from './header'
import formatters from '@/components/mixins/formatters'
import table from '@/components/mixins/table'
import { cleanClone } from '@/components/utils/common'

export default {
  mixins: [formatters, table],
  name: 'Table',
  props: {
    selectedItems: Array,
    client_id: Number,
    client_advert_id: Number
  },
  data () {
    return {
      localSelectedItems: this.selectedItems
    }
  },
  methods: {
    classByStatus (item) {
      let className = this.advertClassByStatus(item)
      if (this.opened.includes(item.id)) {
        className += ' selected-item'
      }
      return className
    },
    handleSelectionItem (item, isSelected) {
      if (isSelected) {
        this.localSelectedItems.push(item)
      } else {
        const index = this.localSelectedItems.findIndex(item => item.id)
        if (index > 0) {
          this.localSelectedItems.splice(index, 1)
        }
      }
      this.$emit('update:selectedItems', this.localSelectedItems)
    },
    handleSelectAll (val) {
      if (val.value) {
        this.localSelectedItems = cleanClone(this.items)
      } else {
        this.localSelectedItems = []
      }
      this.$emit('update:selectedItems', this.localSelectedItems)
    }
  },
  computed: {
    headers () {
      let result = cleanClone(headers)
      if (!this.client_advert_id) {
        let conditions = ['viewed', 'approved', 'offered']
        for (let i = result.length - 1; i >= 0; i--) {
          if (conditions.includes(result[i].value)) {
            result.splice(i, 1)
          }
        }
      }
      return result
    }
  }
}
</script>

<style scoped>
  .phone {
    min-width: 90px;
  }
</style>
