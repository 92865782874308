<template>
  <div v-scroll="handleScrollPosition">
    <div class="d-flex align-center">
      <Create @clickItem="$emit($event.action, $event.id ? {id: $event.id} : null)"/>
      <v-btn
        :disabled="!canSaveCompilation"
        small
        :loading="isSavingData"
        class="success ml-2 mt-3"
        @click="saveCompilation(listType)"
      >
        <v-icon
          small
          class="mr-1"
        >mdi-content-save
        </v-icon>
        Сохранить
      </v-btn>
      <Controls
        :filters="value.requestParams.filters"
        @input="handleControls($event)"
        @list="$emit('list', $event)"
        :loading="loadingTable"
        :client-id="value.client_id"
        :client-advert-id="value.id"
      />
      <DeleteDialog
        v-if="$can('admin')"
        :selected-items="selectedItems"
        @update="debounceFillTable"
      />
    </div>
    <Table
      :options="value.requestParams.options"
      @input="localRequestParams.options = $event"
      :loading="loadingTable"
      :items="items"
      :items-length="itemsLength"
      :client_id.sync="value.client_id"
      :client_advert_id="value.id"
      @detail="handleClickRow"
      :opened.sync="localState.openedItems"
      :selected-items.sync="selectedItems"
    />
  </div>
</template>

<script>
import Table from './List/Table'
import Controls from './List/Controls'
import list from '@/components/mixins/list'
import DeleteDialog from '@/components/views/admin/advert/List/DeleteDialog'
import Create from '@/components/views/admin/advert/Common/Create'
import * as Config from '@/config'

export default {
  mixins: [list],
  name: 'List',
  components: { Create, DeleteDialog, Controls, Table },
  data () {
    return {
      fillTableUrl: '/advert',
      selectedItems: [],
      isSavingData: false
    }
  },
  methods: {
    handleClickRow (event) {
      this.$emit('detail', event)
    }
  },
  computed: {
    listType () {
      return Config.list.value
    }
  }
}
</script>

<style scoped>

</style>
